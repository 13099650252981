import dynamic from 'next/dynamic';

import { getAuthKey } from 'api';
import { Loader } from 'components';
import { baseUrl, AUTH_LS_KEY, LAST_NOT_AUTH_PAGE_KEY } from 'config';
import { redirectBrowserTo } from 'utils';
import { Routes } from 'config';

/**
 * this page works in close conjunction with useAuth hook and common Lemon login page and is built
 * around the idea that authenticated user has authKey stored in local storage.
 *
 * the procedure of authenticating user is:
 *  1. useAuth in any page (dashboard, hotStacks, or profile, detects that user isn't authenticated
 *     and redirects browser to the
 *                              <crm2>/login#<target>
 *     page, where target is either dashboard, hotStacks, or profile/candidateId
 *  2. crm2 login page detects that there is no sid parameter in the current url and
 *     - saves the target to local storage as last not authorized page
 *     - redirects to the Lemon login page
 *  3. Lemon login page
 *     - authenticates user
 *     - sets cookies
 *     - redirects back to the crm2 login page but with the sid parameter now
 *  4. crm2 login page detects the sid parameter and
 *     - retrieves authKey with the getAuthKey API (this API requires cookies to be set, that's why crm2
 *       and lemon login page must be in the same domain)
 *     - retrieves last not authorized page from the local storage and redirects browser to it
 */
const LoginPage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sid = urlParams.get('sid');

  if (!sid) {
    const currentUrl = window.location.href;

    if (currentUrl.indexOf('#') !== -1) {
      const authTarget = currentUrl.substring(currentUrl.indexOf('#') + 1);
      window.localStorage.setItem(LAST_NOT_AUTH_PAGE_KEY, authTarget);
    }
    redirectBrowserTo(`${baseUrl}login?redirectTo=${currentUrl}&passSid=true`);
  } else {
    getAuthKey().then((authKey) => {
      window.localStorage.setItem(AUTH_LS_KEY, authKey);
      const protocol = window.location.protocol;
      const host = window.location.host;
      const target = window.localStorage.getItem(LAST_NOT_AUTH_PAGE_KEY) || Routes.dashboard;
      redirectBrowserTo(`${protocol}//${host}/${target}`);
    });
  }

  return <Loader open={true} />;
};

export default dynamic(() => Promise.resolve(LoginPage), { ssr: false });
